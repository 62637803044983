var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.setQuery}},[_c('template',{slot:"actions"},[_c('RidersExport',{attrs:{"data":_vm.riders}})],1)],2),_c('el-table',{attrs:{"data":_vm.setRiders}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'marketplace-logistics.directory.rider',
            params: { id: scope.row.uuid },
          }}},[_c('span',{staticClass:"font-sm text-primary"},[_vm._v(" "+_vm._s(_vm.formatFullName(scope.row)))])])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Name")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status)?_c('el-tag',{attrs:{"type":_vm.setType(scope.row.status)}},[_vm._v(" "+_vm._s(_vm.formatText(scope.row.status))+" ")]):_c('span',{staticClass:"font-sm"},[_vm._v("-")])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Status")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(_vm.formatPhoneNumber(scope.row.phone_number))+" ")])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Phone Number")])])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm"},[_vm._v(_vm._s(scope.row.email))])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Email address")])])])],2),(
        _vm.allowAccessFor([
          'superadmin',
          'admin',
          'vendor_manager',
          'operations',
        ])
      )?_c('el-table-column',{attrs:{"min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                action: 'edit',
                id: scope.row.uuid,
                index: scope.$index,
              }}},[_vm._v("Edit rider")]),_c('el-dropdown-item',{attrs:{"command":{
                action: 'status',
                id: scope.row.uuid,
                rider: scope.row,
                index: scope.$index,
              }}},[_vm._v("Change Rider Status")]),_c('el-dropdown-item',{staticClass:"text-danger",attrs:{"command":{
                action: 'delete',
                id: scope.row.uuid,
                index: scope.$index,
              }}},[_vm._v("Delete rider")])],1)],1)]}}],null,false,1204259107)}):_vm._e()],1),_c('RiderDelete',{attrs:{"show":_vm.rider.visibility,"rider-id":_vm.riderId},on:{"update:show":function($event){return _vm.$set(_vm.rider, "visibility", $event)},"success":function($event){return _vm.$router.go(0)}}}),_c('RiderStatus',{attrs:{"show":_vm.showStatus,"rider":_vm.singleRider},on:{"update:show":function($event){_vm.showStatus=$event},"success":function($event){return _vm.$router.go(0)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }