<template>
  <div>
    <eden-table-actions :title="title" @search="setQuery">
      <template slot="actions">
        <RidersExport :data="riders" />
      </template>
    </eden-table-actions>
    <el-table :data="setRiders">
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Name</span>
          </div>
        </template>
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'marketplace-logistics.directory.rider',
              params: { id: scope.row.uuid },
            }"
          >
            <span class="font-sm text-primary">
              {{ formatFullName(scope.row) }}</span
            ></router-link
          >
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Status</span>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status" :type="setType(scope.row.status)">
            {{ formatText(scope.row.status) }}
          </el-tag>
          <span v-else class="font-sm">-</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Phone Number</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatPhoneNumber(scope.row.phone_number) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Email address</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="
          allowAccessFor([
            'superadmin',
            'admin',
            'vendor_manager',
            'operations',
          ])
        "
        min-width="60"
      >
        <template slot-scope="scope">
          <el-dropdown @command="command" class="more">
            <span class="el-dropdown-link">
              <i class="eden-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{
                  action: 'edit',
                  id: scope.row.uuid,
                  index: scope.$index,
                }"
                >Edit rider</el-dropdown-item
              >

              <el-dropdown-item
                :command="{
                  action: 'status',
                  id: scope.row.uuid,
                  rider: scope.row,
                  index: scope.$index,
                }"
                >Change Rider Status</el-dropdown-item
              >

              <el-dropdown-item
                :command="{
                  action: 'delete',
                  id: scope.row.uuid,
                  index: scope.$index,
                }"
                class="text-danger"
                >Delete rider</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <RiderDelete
      :show.sync="rider.visibility"
      :rider-id="riderId"
      @success="$router.go(0)"
    />
    <RiderStatus
      :show.sync="showStatus"
      :rider="singleRider"
      @success="$router.go(0)"
    />
  </div>
</template>

<script>
import RiderDelete from "../Riders/Actions/RiderDelete.vue";
import RiderStatus from "../Riders/Actions/RiderStatus.vue";
import RidersExport from "../Riders/RidersExport.vue";

export default {
  name: "CompanyRiders",
  components: {
    RiderStatus,
    RiderDelete,
    RidersExport,
  },
  props: {
    riders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      query: "",
      rider: {
        action: "add",
        visibility: false,
        data: {},
      },
      riderId: "",
      showStatus: false,
      singleRider: {},
    };
  },

  computed: {
    title() {
      return `${this.riders.length} Riders`;
    },
    setRiders() {
      const query = this.query.toLowerCase();

      if (query) {
        return this.riders.filter((rider) => {
          return (
            rider.firstname.toLowerCase().includes(query) ||
            rider.lastname.toLowerCase().includes(query)
          );
        });
      } else {
        return this.riders;
      }
    },
  },

  methods: {
    command(command) {
      this.riderId = command.id;
      this.singleRider = command.rider;
      switch (command.action) {
        case "edit":
          this.rider.action = "edit";
          this.edit();
          break;
        case "status":
          this.rider.action = "status";
          this.showStatus = true;
          break;
        case "delete":
          this.rider.action = "delete";
          this.rider.visibility = true;
          break;
      }
    },
    setQuery(query) {
      this.query = query;
    },
    edit() {
      this.$router.push({
        name: "marketplace-logistics.directory.rider-edit",
        params: { id: this.riderId },
      });
    },
  },
};
</script>
