import axios from "axios";

export default {
  list(params) {
    return axios.get(`marketplace_crm/riders`, { params });
  },
  add(payload) {
    return axios.post(`marketplace_crm/riders`, payload);
  },
  get(id) {
    return axios.get(`marketplace_crm/riders/find/${id}`);
  },
  update(id, payload) {
    return axios.patch(`marketplace_crm/riders/${id}`, payload);
  },
  summary() {
    return axios.get(`marketplace_crm/riders/summary`);
  },
  delete(id) {
    return axios.delete(`marketplace_crm/riders/${id}`);
  },
  payout(id) {
    return axios.get(`marketplace_crm/riders/${id}/payouts`);
  },
  payoutDetails(id) {
    return axios.get(`marketplace_crm/riders/payouts/${id}`);
  },
};
