<template>
  <div>
    <el-button
      type="plain"
      icon="eden-icon-upload"
      :disabled="loading || !data.length"
      @click="showExport = true"
    >
      Export
    </el-button>
    <el-dialog
      title="Export"
      :visible.sync="showExport"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="key === 'name' || key === 'phone'"
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="exporting"
            @click="getExportData"
            :disabled="!form.format || !form.fields.length"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DirectoryRidersExport",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      showExport: false,
      fields: {
        name: "Name",
        status: "Status",
        phone: "Phone Number",
        email: "Email",
        company: "Company",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["name", "phone"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["name", "phone"];
      this.form.format = "";
      this.exporting = false;
      this.showExport = false;
    },

    getExportData() {
      this.exporting = true;
      this.formatExportData(this.data);
    },

    formatExportData(data) {
      let title = "Operations - Riders Directory";
      let headers = {
        sn: "S/N",
      };
      let extra_headers = {};
      let directoryData = [];
      this.form.fields.forEach(
        (key) => (extra_headers[key] = this.fields[key]),
      );
      headers = { ...headers, ...extra_headers };
      directoryData = data.map((rider, index) => {
        return {
          sn: index + 1,
          name: this.formatFullName(rider),
          status: this.formatText(rider.status),
          id: rider.id,
          company: rider.company && rider.company.name,
          phone: rider.phone_number,
          email: rider.email,
        };
      });
      this.exportData = {
        title,
        headers,
        data: directoryData,
        fileName: "Logistics - Riders Directory",
        titleMargin: 34,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.downloadComboCsv,
.downloadComboXls {
  position: absolute;
  opacity: 0;
}
</style>
