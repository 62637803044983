<template>
  <div v-loading="loading">
    <eden-page-header :title="'Companies Directory'" :subtitle="company.name">
      <template slot="actions">
        <company-actions
          v-if="allowAccessFor(['admin', 'operations', 'vendor_manager'])"
          :company="company"
          @update="update"
        >
          <template slot="actions-trigger">
            <el-button type="plain">
              Actions <i class="eden-icon-arrow-down"></i>
            </el-button>
          </template>
        </company-actions>
      </template>
    </eden-page-header>
    <eden-information-card>
      <template slot="content">
        <!-- {{ company }} -->
        <div class="company-profile">
          <div class="company-profile--summary">
            <img :src="getAvatar()" alt="Company Logo" />
            <p class="name">{{ formatToTitleCase(company.name) }}</p>
            <el-tag :class="'small'" :type="setType(company.status)">{{
              formatText(company.status)
            }}</el-tag>
            <el-button :type="'plain'" :size="'small'" @click="edit"
              >Edit Profile</el-button
            >
          </div>
          <eden-information-card
            class="no-border min-width"
            :title="'General Information'"
          >
            <template slot="content">
              <el-table
                :class="'general'"
                :data="generalInformation"
                :show-header="false"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <span class="title font-xsm">{{ scope.row.label }}</span>
                    <p class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </eden-information-card>
          <eden-information-card
            class="no-border min-width"
            :title="'Director'"
          >
            <template slot="content">
              <el-table
                :class="'general'"
                :data="directorInformation"
                :show-header="false"
              >
                <el-table-column>
                  <template slot-scope="scope">
                    <span class="title font-xsm">{{ scope.row.label }}</span>
                    <p class="text font-sm">
                      {{ scope.row.text }}
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </eden-information-card>
        </div>
      </template>
    </eden-information-card>
    <company-riders :riders="company.marketplace_riders" />
  </div>
</template>

<script>
import CompanyActions from "@/components/Logistics/Directory/Companies/Company/Actions/CompanyActions";
import companies from "@/requests/logistics/directory/companies";
import CompanyRiders from "@/components/MarketplaceLogistics/Directory/Companies/CompanyRiders";

export default {
  name: "Company",
  components: {
    CompanyRiders,
    CompanyActions,
  },
  data() {
    return {
      company: {},
    };
  },

  computed: {
    companyId() {
      return this.$route.params.id;
    },
    generalInformation() {
      return [
        {
          label: "Email",
          text: this.company.company_email || "-",
        },
        {
          label: "Address",
          text: this.company.address || "-",
        },
        {
          label: "--",
          text: "-",
        },
      ];
    },
    directorInformation() {
      return [
        {
          label: "Name",
          text:
            this.company.contact_firstname +
            " " +
            this.company.contact_lastname,
        },
        {
          label: "Email Address",
          text:
            this.company.contact_email &&
            this.company.contact_email.toLowerCase(),
        },
        {
          label: "Phone Number",
          text: this.formatPhoneNumber(this.company.contact_phone),
        },
      ];
    },
  },
  created() {
    this.getCompany();
  },
  methods: {
    getCompany() {
      this.loading = true;
      companies
        .get(this.companyId)
        .then((response) => {
          if (response.data.status) {
            this.company = response.data.data[0];
            this.loading = false;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    edit() {
      this.$router.push({
        name: "marketplace-logistics.directory.company-edit",
        params: { id: this.companyId },
      });
    },
    update(action) {
      if (action === "delete") {
        this.$router.push({
          name: "marketplace-logistics.directory.index",
          query: { t: "companies" },
        });
        setTimeout(() => {
          this.$router.go(0);
        }, 100);
      } else {
        this.company.status =
          this.company.status === "active" ? "inactive" : "active";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$profile-width: 320px;

.information-card {
  padding: 0;
  margin-bottom: 48px;

  &.no-border {
    border: 0;
    margin-bottom: 0;
    padding: 16px 0;
    width: 35%;

    div.title {
      padding: 16px !important;
    }

    @media (min-width: 768px) {
      &:not(:last-child) {
        border-right: 1px solid #f0f4f2;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    @media (max-width: 1200px) {
      border-top: 1px solid #f0f4f2;
      border-radius: 0;
    }
  }
}

.company-profile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &--summary {
    padding: 16px 0;
    text-align: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #f0f4f2;

    .name {
      font-weight: 500;
      font-size: 1.125rem;
      margin: 5px 0;
    }

    .phone {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--eden-grey-tertiary);
      display: block;
      margin: 5px 0;
    }

    .el-button {
      margin: 15px auto;
    }
  }
  @media (min-width: 1300px) {
    .company-profile--summary {
      border-right: 1px solid #f0f4f2;
    }
  }
}

.general {
  tr:last-child {
    td {
      padding: 0;

      .cell {
        padding: 0;
      }
    }
  }
}
</style>
